import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["sub_status", "send_follow_up_sms"];

    connect() {
        console.log("treatment action event loaded");
        this.handleSubStatusChange();
        $(this.sub_statusTarget).on('change', (e) => {
            this.handleSubStatusChange();
        });
    }

    handleSubStatusChange() {
        const subStatus = $(this.sub_statusTarget).val();
        const sendSmsField = $(this.send_follow_up_smsTarget);

        if( subStatus === "follow_up" ){
            sendSmsField.find('input').prop('disabled', false);
            sendSmsField.show();
        }else{
            sendSmsField.find('input').prop('disabled', true);
            sendSmsField.hide();
        }

    }
}
