import ReactOnRails from "react-on-rails";

ReactOnRails.setOptions({ turbo: true });

require('./materialize')
import 'select2';

require('./lib/select2_materialize')

import './kyc_diagnoses'

import '@nathanvda/cocoon'

import "chartkick/chart.js"

import Tribute from "tributejs";

import './materialize-form'

import moment from 'moment'
import './util/message_handler'
import './menu'
import './sales'

import './gift_card_leads'
import './appointments_medical_forms'

window.materializeForm.initDate = function () {
    // Disabling the default date conversion to pickadate
}

$(document).on('turbo:before-cache', function() {     // this approach corrects the select 2 to be duplicated when clicking the back button.
    $('#assignee').select2('destroy');
    $('#entity').select2('destroy');
    $('#activity_by').select2('destroy');
});

$(document).on('turbo:render', function() {
    console.log("Turbo Render Fired");
    if (Materialize && Materialize.updateTextFields) {
        initializeSystem();
    }
});

$(document).on('ready', function() {
    console.log("Document Ready Fired");
    initializeSystem();
});

$(document).on('turbo:load', function() {
    console.log("Turbo Load Fired");
    initializeSystem();
});

// At times gets called twice since turbo does not load but render in some cases and loads but does not render in others
function initializeSystem() {

    if (window.portea_portal_initialized) {
        return
    }
    console.log("Initializing")

    window.materializeForm.init();


    $(".main-nav-menu").sideNav();
    $(".action-search").sideNav({edge: 'right', menuWidth: 450});
    $(".action-search-exp").sideNav({edge: 'right', menuWidth: '100%'});

    $('.collapsible, .search').collapsible({
        accordion : false // A setting that changes the collapsible behavior to expandable instead of the default accordion style
    });

    $(".cancel_search").click(function(){
        $('.action-search-exp').sideNav('hide');
        return false;
    })

    Materialize.Modal._count=0;

    $("#search_form > input").keypress(function (event) {
        if (event.which == 13) {
            event.preventDefault();
            $("#search_form").submit();
        }
    });

    $('#c2c_call').modal({
        ready: function(modal, trigger) {
            const event = new Event('c2c:init');
            document.dispatchEvent(event);
        },
    });

    $('#options, #workflow_event_modal').modal({
        ready: function(modal, trigger) {
            modal.children('form').attr('action', trigger.data('url'));
            modal.find("." + trigger.data('event')).toggleClass('hide');
        },
    });

    setupUserSelector($('#assignee'));
    setupUserSelector($('#activity_by'));
    setupUserSelector($('#entity'));



    $('.treatment_observation_type_ids').sm_select();

    $('.datepicker').pickadate({
        selectMonths: true, // Creates a dropdown to control month
        selectYears: 1, // Creates a dropdown of 15 years to control year,
        today: 'Today',
        clear: 'Clear',
        close: 'Ok',
        closeOnSelect: true
    });

    $('.timepicker').pickatime({
        default: 'now', // Set default time: 'now', '1:30AM', '16:30'
        fromnow: 0,       // set default time to * milliseconds from now (using with default = 'now')
        twelvehour: false, // Use AM/PM or 24-hour format
        donetext: 'OK', // text for done-button
        cleartext: 'Clear', // text for clear-button
        canceltext: 'Cancel', // Text for cancel-button,
        container: undefined, // ex. 'body' will append picker to body
        autoclose: false, // automatic close timepicker
        ampmclickable: true, // make AM PM clickable
    });


    Materialize.updateTextFields();

    $('.treatment_tags_list').sm_select({
        multiple:true
    });

    $('.fixed-action-btn').closeFAB();


    $('#monthly_area_cost,#action_event, #treatment_goal_form, #schedule_form, #add_documents, #order_items, #so_order_items, #recent_contacts, #field_activity_form, #vsm_form, #csm_form').on('cocoon:after-insert', function() {

        $('.datepicker').pickadate({
            selectMonths: true, // Creates a dropdown to control month
            selectYears: 1,
            today: 'Today',
            clear: 'Clear',
            close: 'Ok',
            closeOnSelect: true
        });

        $('.timepicker').pickatime({
            default: 'now', // Set default time: 'now', '1:30AM', '16:30'
            fromnow: 0,       // set default time to * milliseconds from now (using with default = 'now')
            twelvehour: false, // Use AM/PM or 24-hour format
            donetext: 'OK', // text for done-button
            cleartext: 'Clear', // text for clear-button
            canceltext: 'Cancel', // Text for cancel-button,
            container: undefined, // ex. 'body' will append picker to body
            autoclose: false, // automatic close timepicker
            ampmclickable: true, // make AM PM clickable
        });

        $('select').material_select();

        Materialize.updateTextFields();

        $('.treatment_observation_type_ids').sm_select();

    });

    var smsDialog = $('#sms_dialog');
    smsDialog.modal();

    smsDialog.on('ajax:error', function(event, xhr, status, error) {
        alert("An error occurred. Please contact support");
        $('#sms_dialog').modal('close');

    });

    $('#options').on('ajax:success', function(event, xhr, status, error) {
        if($("#error_result").html() == "")
            $('#options').modal('close');
    });

    $('#options').on('ajax:beforeSend', function(event, xhr, status, error) {
        $("#error_result").html("");
    });

    $('#obs_coll, #treatment_form, #observation_points, #referrer_form, #area_form').on('cocoon:after-insert', function() {
        $('select').material_select();
        Materialize.updateTextFields();

        $('.datepicker').pickadate({
            selectMonths: true, // Creates a dropdown to control month
            selectYears: 1,
            today: 'Today',
            clear: 'Clear',
            close: 'Ok',
            closeOnSelect: true
        });

        $('.phama_info_add').hide();
        $('.covid_info_add').hide();
        $('.key_master_add').hide();
    });

    $('#target_form, #target_city_form, #bdo_goal_templates, #bdo_goals, #medic_proposal_form, #pg_center, #key_account_mou, #pharma_purchase_infos, #action_pharma_info_form, #stockist, #comm_type, #client_patient_registration, .device_ctr').on('cocoon:after-insert', function() {
        $('select').material_select();
        Materialize.updateTextFields();
    });

    $('#obs_measurement_type').change(function () {
        const selected_value = $('#obs_measurement_type option:selected').text();
        if (selected_value === 'Choice') {
            $('#observation-choice').show();
            $('#reference').hide();
        } else if (selected_value === 'Number') {
            $('#observation-choice').hide();
            $('#reference').show();
        }
        else {
            $('#observation-choice').hide();
            $('#reference').hide();
        }
    });


    // hide flash-container after some time
    $('.flash-container').delay(3000).fadeOut();

    window.portea_portal_initialized = true
}

function setupUserSelector(element) {
    element.sm_select({
        ajax: {
            url: () => element.data('url') + '/' + getUrlParams(),
            dataType: 'json',
            delay: 400,
        }
    });

    var userInfo = element.data('user-id');

    if (userInfo > 0) {
        var option = new Option(element.data('user-name'), userInfo, true, true)
        element.append(option).trigger('change');

    }
}

function getUrlParams(){
    var params = $('#assignee_profession').val();
    if($('#health_angel_area').length){
        params = params + '/' + $('#health_angel_area').val();
    }
    if($('#referrer_brand_id').val() != null &&  $('#referrer_brand_id').val() !== undefined){
        params = $('#referrer_brand_id').val()+'?patientType=company';
    }

    return params;
}


